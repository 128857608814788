import TAGSORT,{taglist} from "../class/tagsort";

const [w,d] = [window,document];

const itemTemplate =  `
  <ul class="works_list" :class="[ 'is-paged-' + paged ]">
    <li v-for="(article , ind) in pagedArticles">
      <a :href="article.href" class="news-item">
        <figure>
          <img :src="article.thumb" :alt="article.title" class="news-item__img">
        </figure>
        <div class="desc">
          <p class="cat" v-if="article.category.name">{{ article.category.name }}</p>
          <p class="ttl">{{ article.title }}</p>
          <ul class="tag_list" v-if="article.keywords.length">
            <li class="tag" v-for="(tag, ind) in article.keywords">#{{ tag }}</li>
          </ul>
        </div>
      </a>
    </li>
  </ul>
  `;

class taglist_custom extends taglist {
  constructor( opt, parent ) {
    super( opt, parent );
  }
  insertContainer ( tag = `<taglist />`) {
    const container = d.querySelector( this.opt.container || '.rwd002-container');
    const pagination = d.querySelector( this.opt.pagination || '.rwd002-pagination');
    const is_list = d.body.classList.contains('is--list');
    if( !container ) return false;

    // top
    if( is_list ){
      container.insertAdjacentHTML('beforebegin', tag);
    }

  }
}

const domReady = () => {
  
  const Tagsort = new TAGSORT({
    list: {
      itemTemplate,
      perpage: 12, // 一度に表示する記事数
    },
    taglist: {
      pagination: '.rwd002-pagination', // taglistを表示する基準の要素
    },
    related: {
      disp_detail: false
    }
  });

  d.addEventListener('tagsort.beforeInit', e => {
    const TAGSORT = e.detail;
    Tagsort.taglist = new taglist_custom( TAGSORT.opt.taglist, TAGSORT );
  });

  Tagsort.render();
};

export default function WORKS () {
  // テンプレートが用意したDOMContentLoaded（テンプレートインスタンスが引数に入る）
  d.addEventListener('rwd002.beforeDomready', e => domReady(e.detail) );
}