import GET_ARTICLES from "../class/getArticles";
import Swiper from "swiper/bundle";

const [ w, d, mq ] = [window, document, window.matchMedia( "(max-width: 900px)" ) ];
const domReady = TPL => {
  new BLOCKS;
};

class BLOCKS {
  constructor() {
    this.mainvisual();
    this.news();
    this.works();
  }
  
  mainvisual () {
    let Mv = document.querySelector('.mainvisual');
    window.addEventListener('load', function() {
      Mv.classList.add('loaded');
    });

    //メインスライダー
    const mvSlide = new Swiper('.mv_slide[data-plugin="mainSlider"]',{
      direction: 'vertical',
      loop: true,
      effect: 'fade',
      speed: 1200,
      allowTouchMove: false,
      fadeEffect: {
        crossFade: true, // クロスフェードを有効にする（フェードモードの場合 true 推奨）
      },
      autoplay:{
        delay: 4000,
        disableOnInteraction: false,
        waitForTransition: false
      },
      pagination: {
        el: ".mv-pager",
        clickable: true,
      },
    });

    // news
    const news_ticker = new GET_ARTICLES('.mainvisual .news_wrap[data-plugin="getArticles"]');
    news_ticker.makeItem = (item,content) => `
      <p class="ttl">NEWS</p>
      <a href="${item.href}">
        <p class="info"><span class="date">${item.date}</span><span class="cate">${item.category.name}</span></p>
        <p class="article_ttl">${item.title}</p>
      </a>`;
    // news_ticker.makeItem = (item) => console.log(item);
    news_ticker.render();
  }

  works () {
    const getArticles = this.fetchArticles({
      content: 'works',
      post_per_page: 10
    });
    const getCategories = this.fetchCategories('works');
    const appendSlide = (item,slider) => {
      slider.appendSlide([`
        <div class="swiper-slide">
          <a href="${item.href}">
            <figure>
              <img src="${item.thumb}" alt="${item.title}">
            </figure>
            <div class="text_wrap">
              ${(item.category.name)? `<p class="cate">${item.category.name}</p>`: ''}
              <p class="ttl">${item.title}</p>
            </div>
          </a>
        </div>
      `]);
    }
    const slider = new Swiper('.works_slide', {
      grabCursor: true,
      speed: 400,
      slidesPerView: 1.2,
      spaceBetween: 27,
      autoplay: {
        delay: 5000,
        disableOnInteraction: false,
      },
      navigation: {
        nextEl: '.works-next',
        prevEl: '.works-prev',
      },
      pagination: {
        el: '.works-pagination',
        type: 'progressbar',
        clickable: true,
      },
      breakpoints: {
        500: {
          slidesPerView: 'auto',
          spaceBetween: 46,
        }
      },
    });
    slider.removeAllSlides();
    Promise.all([getArticles,getCategories]).then( ([articles,cats]) => {
      d.querySelector('.works_slide .swiper-wrapper').innerHTML = '';
      articles.map( article => {
        article.category = cats.find( v => v.id == article.category ) || {id: '0', href: '/works/?mode=list&cat=0', name: 'その他', turn: 99};
        appendSlide(article,slider);
      });
      slider.slideToLoop(0);
    });
  }

  fetchCategories (content) {
    return fetch(`/assets/api/getCategories/?content=${content}`)
      .then(r => r.json())
      .catch(e => { throw new Error(e)});
  }

  fetchArticles (opt){
    return fetch(`/assets/api/getArticles/?${ new URLSearchParams(opt)}`)
    .then(r => r.json())
    .catch(e => { throw new Error(e)});
  }

  news () {
    //NEWS
    const getArticle = new GET_ARTICLES('.block-news .news_list');
    getArticle.makeItem = (item,content) => `
    <li>
      <a${(item.is_new)? ' class="is-new"': ''} href="${item.href}">
        <p class="info"><span class="date">${item.date}</span><span class="cate">${item.category.name}</span></p>
        <p class="ttl">${item.title}</p>
      </a>
    </li>`;
    // getArticle.makeItem = (item) => console.log(item);
    getArticle.render();
  }

}

export default function HOME () {
  // テンプレートが用意したDOMContentLoaded（テンプレートインスタンスが引数に入る）
  d.addEventListener('rwd002.beforeDomready', e => domReady(e.detail) );
}